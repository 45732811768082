import React from 'react';
import { navigate } from 'gatsby';
import { useEffectOnce } from '../utils/useEffectCustom';

export default () => {
  const browser = typeof window !== 'undefined' && window;

  useEffectOnce(() => browser && navigate('/'));
  return <></>;
};
